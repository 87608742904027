<template>
  <div>
    <Navbar></Navbar>
    <!-- 轮播图 -->
    <div id="carouselExample" class="carousel slide" style="margin-top: 80px;">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../static/carousel/1.jpg" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="../../static/carousel/2.jpg" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="../../static/carousel/3.jpg" class="d-block w-100" alt="..." />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="container">
      <!-- 公司简介 -->
      <div class="intro">
        <!-- 文字 -->
        <div class="title">
          <div class="intro-title">
            <div class="E-title">COMPANY PROFILE</div>
            <div class="C-title">公司简介</div>
          </div>
          <div class="section">
            <p>东莞市良井信息技术有限公司成立于2016年，核心团队来自著名世界500强企业，拥有近20年的企业流程规划与IT系统建设经验，为雀巢咖啡、新科磁电、中国烟草、立讯精密、锦亿科技等约100家大中小企业提供高品质的流程规划与IT服务，数字化项目验收率达100%。</p>
            <p>良井信息提供一站式的“从订单到回款”IT协同系统，为9个重点行业提供解决方案，致力于打通企业各职能部门的工作壁垒，消除企业不同层级的沟通障碍，让企业员工及上下游在同一个平台，用同一套模式协同运作，实现管理制度化、制度流程化、流程IT化，最终实现客户满意度提升和内部降本增效！</p>
          </div>
          <div class="btns" @click="goAbout">
            <a class="btn1">MORE+</a>
          </div>
        </div>
        <!-- 图片 -->
        <div class="img">
          <img src="../../static/983d3eaf73325f9ff4d571bf2f1b70c0.png" alt />
        </div>
      </div>
      <div class="Tag">
        <div class="list" v-for="(item,index) in superiority" :key="index">
          <div class="title"><h1>{{item.title.replace(/[^\d]/g,' ')}}</h1><span>{{ item.title.replace(/^\d*/g,'')}}</span></div>
          <p>{{item.text }}</p>
        </div>
      </div>
      <!-- 产品展示 -->
      <!-- <div class="showcase">
        <div class="showcase-title">
          <div class="E-title">PRODUCT DISPLAY</div>
          <div class="C-title">产品展示</div>
        </div>
        <div class="row row-cols-1 row-cols-md-4 g-4">
          <div class="col" v-for="item in 4" :key="item">
            <div class="card card-box">
              <img src="../../static/card1.png" class="card-img-top" alt="..." />
              <div class="card-body">
                <p class="card-text">预算执行决策分析系统</p>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
    <!--产品特点 -->
    <!-- <div class="trait">
      <div class="container">
        <div class="trait-title">
          <div class="E-title">PRODUCT FEATURES</div>
          <div class="C-title1">产品展示</div>
        </div>
        <div class="row row-cols-1 row-cols-5">
          <div class="col" v-for="item in 5" :key="item">
            <div v-if="item%2==0" class="card trait-card">
              <img src="../../static/card1.png" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">
                  <img src="../../static/三角.png" width="16" alt />&nbsp;&nbsp;经济运行监测分析数字化系统
                </h5>
                <p class="card-text">实现从“宏观、中观、微观”三个层面对地方政府经济运行的相关指标，进行实时动态监测、预警、预测、政策仿真等;</p>
              </div>
            </div>
            <div v-else class="card trait-card">
              <div class="card-body">
                <h5 class="card-title">
                  <img src="../../static/三角.png" width="16" alt />&nbsp;&nbsp;经济运行监测分析数字化系统
                </h5>
                <p class="card-text">实现从“宏观、中观、微观”三个层面对地方政府经济运行的相关指标，进行实时动态监测、预警、预测、政策仿真等;</p>
              </div>
              <img src="../../static/card1.png" class="card-img-top" alt="..." />
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <!-- 新闻中心 -->
    <!-- <div class="new">
      <div class="container">
        <div class="new-title">
          <div class="E-title">NEWS CENTER</div>
          <div class="C-title1">新闻中心</div>
          <div class="arrows" @click="goNews">
            <img src="../../static/rightArrows.png" width="24" alt />
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-4 g-4">
          <div class="col" v-for="item in 4" :key="item">
            <div class="card card-box">
              <img src="../../static/card1.png" class="card-img-top" alt="..." />
              <div class="card-body">
                <div class="card-fun">
                  <div class="date">
                    <h4>11</h4>
                    <p>2024-09</p>
                  </div>
                  <div class="browse">
                    <i class="bi bi-eye-fill"></i>
                    <span>456</span>
                  </div>
                  <div>
                    <i class="bi bi-chat-right-dots-fill"></i>
                    <span>0</span>
                  </div>
                </div>
                <p class="card-text">预算执行决策分析系统</p>
                <p
                  class="p"
                >数据作为新型生产要素，已经深刻融入经济社会各领域。9月9日下午，南湖数据产业联盟召开第一届第一次会议。第一阶段审议并通过了《南湖数据产业联盟章程草案》，选举产生了第一届南湖数据产业联盟理事单位、理事长</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <Footer></Footer>
    <div class="menu">
      <div class="menu-item">
        <i class="iconfont icon-youjian"></i>
      </div>
      <div class="menu-item">
        <i class="iconfont icon-xinxi"></i>
      </div>
      <div class="menu-item" @click="()=>{isShow=true}">
        <i class="iconfont icon-erweima"></i>
      </div>
      <div class="menu-item">
        <i class="iconfont icon-shangjiantou"></i>
      </div>
    </div>
    <div class="ewm" v-if="isShow" @click="()=>{isShow=false}">
      <div class="box">
        <div class="img1">
          <img src="../../static/erweima.jpg" alt />
          <div>打开手机扫描二维码</div>
        </div>
        <div>
          <i class="iconfont icon-quxiao"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/navbar";

export default {
  components: {
    Footer,
    Navbar
  },
  data() {
    return {
      isShow: false,
      superiority: [{ title: "1个", text: "“从订单到回款” IT系统" },{ title: "9个", text: "重点行业解决方案" },{ title: "20年", text: "专注流程IT建设经验" },{ title: "100+", text: "大中小客户成功案例" },]
    };
  },
  methods: {
    goAbout() {
      this.$router.push({ name: "aboutus" });
    },
    goNews() {
      this.$router.push({ name: "news" });
    }
  }
};
</script>

<style scoped lang="less">
#carouselExample {
  width: 100% !important;
}
.E-title {
  font-family: 宋体;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  color: rgba(46, 60, 102, 0.2);
}
.C-title {
  margin-top: 10px;
  font-family: 微软雅黑;
  font-size: 50px;
  font-weight: normal;
  font-style: normal;
  color: rgba(46, 60, 102);
}
.C-title1 {
  margin-top: 10px;
  font-family: 微软雅黑;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  color: rgba(46, 60, 102);
}
.intro {
  display: flex;
  justify-content: space-around;
  padding: 180px 0;
  width: 100%;
  .title {
    margin-right: 220px;
    .intro-title {
      text-align: left;
    }
    .section {
      margin-top: 60px;
      font-family: 微软雅黑;
    }
    .btns {
      margin-top: 40px;
      .btn1 {
        display: inline-block;
        width: 160px;
        height: 46px;
        text-align: center;
        line-height: 46px;
        color: #fff;
        border-radius: 100px;
        background: rgb(46, 60, 102);
      }
    }
  }
  .img {
    margin-top: 60px;
  }
}
.img :hover {
  transform: scale(1.2);
}
.showcase {
  width: 100%;
  padding-bottom: 100px;
  .showcase-title {
    margin: 120px 0;
    text-align: right;
  }
  .card {
    height: 410px;
    padding-top: 100px;
    .card-box {
      text-align: center;
    }
    .card-body {
      p {
        text-align: center;
        margin-bottom: 0px;
      }
    }
  }
}
.Tag {
  display: flex;
  justify-content: space-between;
  padding-bottom: 200px;
  font-family: 宋体;
  .list {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    text-align: center;
    color: #fff;
    background: #2e3c66;
    border-radius: 50%;
    .title{
      display: flex;
      align-items: center;
      h1{
        font-size: 50px;
        font-weight: 800;
        margin-right: 5px;
      }
      span{
        font-weight: 800;
      }
    }
    p{
      width: 140px;
    }
  }
}

// 产品展示
.trait {
  background: url("../../static/bg.jpg");
  width: 100%;
  height: 984px;
  padding-top: 160px;
  .row {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .card {
    margin-top: 80px;
    border-radius: 0px !important;
    .card-title {
      color: #1a98ff;
      font-size: 18px;
    }
  }
  .trait-card {
    padding: 20px 10px;
    height: 448px;
  }
}
// 新闻中心
.new {
  margin-top: 200px;
  margin-bottom: 180px;
  p {
    margin-top: 10px;
  }
  .new-title {
    display: flex;
    align-items: end;
    flex-direction: column;
    .arrows {
      margin: 50px 0px;
      width: 50px;
      height: 50px;
      line-height: 45px;
      text-align: center;
      border: 1px solid #000;
      background: rgb(46, 60, 102);
      border-radius: 100%;
      font-size: 26px;
      color: #fff;
    }
  }
  .card-fun {
    display: flex;
    align-items: end;
    span {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .bi {
    color: #e6b138;
  }
  .browse {
    margin: 0 30px;
  }
  .date {
    width: 60px;
    height: 60px;
    border-radius: 3px;
    background: #e6b138;
    color: #fff;
    padding-top: 10px;
    text-align: center;
    h4 {
      font-size: 20px;
    }
    p {
      margin-top: -5px;
      font-size: 13px;
    }
  }
  .p {
    font-size: 14px;
    color: #666;
  }
}
.menu {
  position: fixed;
  top: 50%;
  right: 20px;
  .menu-item {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    text-align: center;
    margin: 5px;
    border-radius: 2px;
    border: 1px solid #ddd;
  }
}
.ewm {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  .box {
    position: relative;
    top: 40%;
    left: 45%;
    .img1 {
      width: 200px;
      height: 230px;
      background: #fff;
      text-align: center;
      padding-top: 20px;
      border-radius: 10px;
      img {
        width: 160px;
      }
    }
  }
}
</style>