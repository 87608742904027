<template>
  <div class="box">
    <div class="footer container">
      <div class="footer-box">
        <div class="logo">
          <img src="../static/logo.png" width="115" height="105" alt />
        </div>
        <div class="tieku">
          <h5>快捷导航</h5>
          <ul>
            <li v-for="(item,index) in listdata" :key="index">
              <a href>{{item.title}}</a>
            </li>
          </ul>
        </div>
        <div class="tieku">
          <h5>商务合作</h5>
          <img src="../static/erweima.jpg" width="130" alt="无法加载" />
        </div>
        <div class="tieku">
          <h5>联系我们</h5>
          <p>地址：东莞市莞城区旗峰路200号万科中心2号商业办公楼1207/1208</p>
          <p>梁先生：18902606781</p>
        </div>
      </div>
      <div></div>
      <div class="bottom-box">
        <div class="bottom">备案号：粤ICP备17040447号 Copyright © 2012-2030 dgliangjing. 东莞良井 版权所有</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listdata: [
        { title: "网站首页" },
        { title: "企业简介" },
        { title: "产品中心" },
        { title: "新闻中心" },
        { title: "联系我们" }
      ]
    };
  }
};
</script>

<style scoped lang="less">
h5{
  margin-bottom: 15px;
}
.box {
  width: 100%;
  height: 495px;
  background: url(../static/footerbg.jpg);
  background-size: cover; /* 确保图片覆盖整个元素 */
  .footer {
    color: #fff;
    .footer-box {
      padding-top: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        margin-right: 100px;
      }
      .tieku {
        width: 25%;
        border-left: #fff 1px double;
        height: 220px;
        padding-left: 60px;
        ul {
          padding: 0px !important;
          li {
            margin-top: 10px;
          }
          a {
            color: #fff;
          }
        }
      }
    }
  }
  .bottom-box {
    margin-top: 120px;
    width: 100%;
    line-height: 70px;
    .bottom {
      text-align: center;
    }
  }
}
</style>