var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"intro"},[_c('div',{staticClass:"title"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"btns",on:{"click":_vm.goAbout}},[_c('a',{staticClass:"btn1"},[_vm._v("MORE+")])])]),_vm._m(3)]),_c('div',{staticClass:"Tag"},_vm._l((_vm.superiority),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(item.title.replace(/[^\d]/g,' ')))]),_c('span',[_vm._v(_vm._s(item.title.replace(/^\d*/g,'')))])]),_c('p',[_vm._v(_vm._s(item.text))])])}),0)]),_c('Footer'),_c('div',{staticClass:"menu"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"menu-item",on:{"click":()=>{_vm.isShow=true}}},[_c('i',{staticClass:"iconfont icon-erweima"})]),_vm._m(6)]),(_vm.isShow)?_c('div',{staticClass:"ewm",on:{"click":()=>{_vm.isShow=false}}},[_vm._m(7)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel slide",staticStyle:{"margin-top":"80px"},attrs:{"id":"carouselExample"}},[_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('img',{staticClass:"d-block w-100",attrs:{"src":require("../../static/carousel/1.jpg"),"alt":"..."}})]),_c('div',{staticClass:"carousel-item"},[_c('img',{staticClass:"d-block w-100",attrs:{"src":require("../../static/carousel/2.jpg"),"alt":"..."}})]),_c('div',{staticClass:"carousel-item"},[_c('img',{staticClass:"d-block w-100",attrs:{"src":require("../../static/carousel/3.jpg"),"alt":"..."}})])]),_c('button',{staticClass:"carousel-control-prev",attrs:{"type":"button","data-bs-target":"#carouselExample","data-bs-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"visually-hidden"},[_vm._v("Previous")])]),_c('button',{staticClass:"carousel-control-next",attrs:{"type":"button","data-bs-target":"#carouselExample","data-bs-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"visually-hidden"},[_vm._v("Next")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-title"},[_c('div',{staticClass:"E-title"},[_vm._v("COMPANY PROFILE")]),_c('div',{staticClass:"C-title"},[_vm._v("公司简介")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('p',[_vm._v("东莞市良井信息技术有限公司成立于2016年，核心团队来自著名世界500强企业，拥有近20年的企业流程规划与IT系统建设经验，为雀巢咖啡、新科磁电、中国烟草、立讯精密、锦亿科技等约100家大中小企业提供高品质的流程规划与IT服务，数字化项目验收率达100%。")]),_c('p',[_vm._v("良井信息提供一站式的“从订单到回款”IT协同系统，为9个重点行业提供解决方案，致力于打通企业各职能部门的工作壁垒，消除企业不同层级的沟通障碍，让企业员工及上下游在同一个平台，用同一套模式协同运作，实现管理制度化、制度流程化、流程IT化，最终实现客户满意度提升和内部降本增效！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../static/983d3eaf73325f9ff4d571bf2f1b70c0.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item"},[_c('i',{staticClass:"iconfont icon-youjian"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item"},[_c('i',{staticClass:"iconfont icon-xinxi"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item"},[_c('i',{staticClass:"iconfont icon-shangjiantou"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"img1"},[_c('img',{attrs:{"src":require("../../static/erweima.jpg"),"alt":""}}),_c('div',[_vm._v("打开手机扫描二维码")])]),_c('div',[_c('i',{staticClass:"iconfont icon-quxiao"})])])
}]

export { render, staticRenderFns }