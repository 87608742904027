<template>
  <div>
    <!-- 导航栏 -->
    <nav class="navbar navbar-expand-lg bg-body-tertiary nav">
      <div class="container-fluid">
        <a class="navbar-brand company" href="#">
          <img
            src="@/static/logo.png"
            alt="Logo"
            width="90"
            height="80"
            class="d-inline-block align-text-top"
          />
          <div class="name">
            <div>东莞市良井信息技术有限公司</div>
            <div class="Ename">Dongguan Liangjing Information Technology Co., LTD</div>
          </div>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li :class="url==item.url?'nav-item active' :'nav-item' " v-for="item in navList" :key="item.id">
              <a v-if="item.url=='home'" class="nav-link" aria-current="page" href="#/">{{ item.title }}</a>
              <a v-else class="nav-link" aria-current="page" :href="'#/'+item.url">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url:this.$router.history.current.name,
      navList: [
        {
          id: 0,
          url: "home",
          title: "首页"
        },
        {
          id: 1,
          url: "aboutus",
          title: "关于我们"
        },
        // {
        //   id: 2,
        //   url: "professional",
        //   title: "专业视角"
        // },
        // {
        //   id: 3,
        //   url: "product",
        //   title: "产品中心"
        // },
        {
          id: 4,
          url: "case",
          title: "系统案例"
        },
        // {
        //   id: 5,
        //   url: "coreteam",
        //   title: "核心团队"
        // },
        // {
        //   id: 6,
        //   url: "honor",
        //   title: "资质荣誉"
        // },
        {
          id: 7,
          url: "news",
          title: "新闻中心"
        },
        {
          id: 8,
          url: "contactUs",
          title: "联系我们"
        }
      ]
    };
  },
  mounted(){
    console.log(this.$router.history.current);
  }
};
</script>

<style scoped lang="less">
@media (max-width: 992px) {
  .name {
    display: none;
  }
}
.navbar {
  padding: 0px !important;
}
.navbar-brand {
  padding: 0px !important;
}
.navbar-collapse {
  flex-grow: 0;
  align-items: center;
}
.nav {
  width: 100%;
  background: #fff !important;
  position: fixed;
  top: 0px;
  z-index: 99;
  .company {
    display: flex;
    margin-left: 6.25rem;
    .name {
      margin-left: 0.625rem;
      align-self: center;
      div {
        font-family: 微软雅黑;
        font-size: 1.25rem;
        font-weight: bold;
        font-style: normal;
        color: rgb(41, 36, 133);
        background-color: transparent;
        text-align: left;
      }
      .Ename {
        font-size: 0.7rem;
        font-weight: normal;
      }
    }
  }
  .left {
    margin-left: 11.25rem;
  }
}

.navbar-nav {
  a {
    font-size: 1.125rem;
    width: 8.125rem;
  }
  .active {
    a {
      color: #3d85c6 !important;
    }
  }
}
.nav-item :hover {
  color: #3d85c6;
}
</style> 