import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('../views/aboutus/index.vue')
  },
  {
    path: '/professional',
    name: 'professional',
    component: () => import('../views/professionalPerspective/index.vue')
  },
  {
    path: '/professionalDetail',
    name: 'professionalDetail',
    component: () => import('../views/professionalPerspective/detail.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/index.vue')
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import('../views/product/detail.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case/case.vue')
  },
  {
    path: '/casedetail',
    name: 'casedetail',
    component: () => import('../views/case/detail.vue')
  },{
    path: '/honor',
    name: 'honor',
    component: () => import('../views/honor/index.vue')
  },
  {
    path: '/honordetail',
    name: 'honordetail',
    component: () => import('../views/honor/detail.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs/index.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news/index.vue')
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: () => import('../views/news/detail.vue')
  },
  {
    path: '/coreteam',
    name: 'coreteam',
    component: () => import('../views/coreteam/index.vue')
  },
  {
    path: '/coreteamdetail',
    name: 'coreteamdetail',
    component: () => import('../views/coreteam/detail.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
